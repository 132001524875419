import React, {useState} from 'react';
import {AccountsStatement} from '../../../../networking/accounts/statements/AccountsStatement';
import {Stack, Button, Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {groupBy} from 'lodash';
import {
    stableSort,
    getComparator,
    Order,
    convertToListOfLabel
} from '../../../../components/TPOTable/TPOTableFunctions';
import {
    currencyFormatting,
    rateFormatting,
    dateFormatting
} from '../../../../common/helpers';
import TPOTable, {Header} from '../../../../components/TPOTable/TPOTable';
import TPOExportDropdown from '../../../../components/TPOExportDropdown/TPOExportDropdown';
import {VERIBANC_RATINGS_DROPDOWN_TESTID} from "../AccountGraph/AccountGraph";
import {InstitutionRating} from "../../../../networking/accounts/ratings/InstitutionRating";

export const ACCOUNTS_PAGE_DROPDOWN_TESTID = 'ACCOUNTS_PAGE_DROPDOWN_TESTID';

export interface AccountTableRow {
    account: string;
    financialInstitution: string;
    type: string;
    balanceAsOf: string;
    lastBalance: number;
    monthlyAverage: number;
    monthlyInterest: number;
    interestRate: number;
    status: string;
}

interface AccountTableProps {
    statements: AccountsStatement[];
    ratings: InstitutionRating[]
}

const AccountTable = (props: AccountTableProps) => {
    const {t} = useTranslation();
    const [showAll, setShowAll] = useState(false);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof AccountTableRow>('account');

    const accountTableHeaders: Header[] = [
        {
            label: t('listOfAccounts.accountTableAccountHeader'),
            numeric: false,
            id: 'account',
            showToolTip: false
        },
        {
            label: t('listOfAccounts.accountTableFinancialInsititutionHeader'),
            numeric: false,
            id: 'financialInstitution',
            showToolTip: false
        },
        {
            label: t('listOfAccounts.accountTableTypeHeader'),
            numeric: false,
            id: 'type',
            showToolTip: false
        },
        {
            label: t('listOfAccounts.accountTableBalanceHeader'),
            numeric: false,
            id: 'balanceAsOf',
            showToolTip: false
        },
        {
            label: t('listOfAccounts.accountTableLastBalanceHeader'),
            numeric: true,
            id: 'lastBalance',
            showToolTip: false
        },
        {
            label: t('listOfAccounts.accountTableMonthlyAverageHeader'),
            numeric: true,
            id: 'monthlyAverage',
            showToolTip: false
        },
        {
            label: t('listOfAccounts.accountTableMonthlyInterestHeader'),
            numeric: true,
            id: 'monthlyInterest',
            showToolTip: true,
            toolTipHeader: t('listOfAccounts.monthlyRateToolTipTitle'),
            toolTipText: t('listOfAccounts.monthlyRateToolTipContent')
        },
        {
            label: t('listOfAccounts.accountTableInterestRateHeader'),
            numeric: true,
            id: 'interestRate',
            showToolTip: true,
            toolTipHeader: t('listOfAccounts.interestRateToolTipTitle'),
            toolTipText: t('listOfAccounts.interestRateToolTipContent')
        },
        {
            label: t('listOfAccounts.accountTableAccountStatus'),
            numeric: false,
            id: 'status',
            showToolTip: false
        }
    ];

    const toQuarter = new Map([
        ['1', 'Q4'],
        ['3', 'Q1'],
        ['4', 'Q1'],
        ['6', 'Q2'],
        ['7', 'Q2'],
        ['9', 'Q3'],
        ['10', 'Q3'],
        ['12', 'Q4'],
        ['', ''],
        [undefined, '']
    ]);

    const createData = (statements: AccountsStatement[]): AccountTableRow[] => {
        return statements.map((statement) => {
            return {
                account: `${statement.accountName} - ${statement.accountNumber}`,
                financialInstitution: statement.institutionName,
                type: statement.accountType,
                balanceAsOf: statement.balanceAsOf,
                lastBalance: statement.lastBalance,
                monthlyAverage: statement.monthlyAverage,
                monthlyInterest: statement.monthlyInterest,
                interestRate: statement.interestRate,
                status: statement.isClosed ? 'Closed' : 'Open'
            };
        });
    };

    const rows = createData(props.statements);

    const sortedRows = stableSort(rows, getComparator(order, orderBy));

    const getDataPoints = (obj: AccountTableRow) => {
        // converts object to list of strings
        return [
            obj.account,
            obj.financialInstitution,
            obj.type,
            new Date(obj.balanceAsOf).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                timeZone: 'UTC'
            }),
            currencyFormatting(Number(obj.lastBalance), 2),
            currencyFormatting(Number(obj.monthlyAverage), 2),
            currencyFormatting(Number(obj.monthlyInterest), 2),
            rateFormatting(Number(obj.interestRate) * 100),
            obj.status
        ];
    };

    const ratingFormat = (colorRating?: string, starRating?: string, ribbonRating?: string) => {
        let rating: string = '';
        if (colorRating) rating += colorRating;
        if (starRating) rating += '/' + starRating;
        if (ribbonRating && ribbonRating != 'None') rating += '/' + ribbonRating;
        return rating;
    }

    const blankRow = () => {
        return ['', '', '', '', ''];
    }

    const getInstructionRows = (ratingInstructionText: string) => {
        let instructionLines: string[] = ratingInstructionText.split("\n");
        let instructionRows = instructionLines.map(line => [line, '', '', '', '']);
        instructionRows.splice(0, 1);
        return instructionRows;
    }

    const getFirstInstructionRow = (ratingInstructionText: string) => {
        let instructionLines: string[] = ratingInstructionText.split("\n");
        return [instructionLines[0], '', '', '', ''];
    }

    const getRatingRow = (institutionName: string, ratingData: InstitutionRating[]) => {
        let quarterRatings = ratingData.map(rating => ratingFormat(rating.colorRating, rating.starRating, rating.ribbonRating));
        quarterRatings.unshift(institutionName);
        return quarterRatings;
    }

    const getQuarterHeaderRow = (ratings: [string, InstitutionRating[]]) => {
        if (ratings && ratings[1]) {
            let quarterHeaderRow = ratings[1].map(rating => {
                const monthOption: Intl.DateTimeFormatOptions = {'month': 'numeric'};
                let quarter = toQuarter.get(dateFormatting(rating.quarterEnding, monthOption));
                const yearOption: Intl.DateTimeFormatOptions = {'year': 'numeric'};
                let year = dateFormatting(rating.quarterEnding, yearOption);
                if (quarter) return quarter + '-' + year;
                else return 'Q' + '-' + year;
            });
            quarterHeaderRow.unshift('');
            return quarterHeaderRow;
        } else return [];
    }

    const getQuarterEndingDates = (ratings: [string, InstitutionRating[]]) => {
        if (ratings && ratings[1]) {
            return ratings[1].map(rating => dateFormatting(rating.quarterEnding))
        } else return [];
    }

    const getFdicReleaseDates = (ratings: [string, InstitutionRating[]]) => {
        if (ratings && ratings[1]) {
            return ratings[1].map(rating => dateFormatting(rating.fdicReleaseDate))
        } else return [];
    }

    const quarterEndingAndFdicReleaseRow = (ratings: [string, InstitutionRating[]]) => {
        const rowLabel = t('listOfAccounts.ratings.csvFdicText');
        const quarterDates = getQuarterEndingDates(ratings);
        const fdicDates = getFdicReleaseDates(ratings);
        let quarterAndFdicReleaseDates: string[] = quarterDates.map(
            (quarterDate, index) => quarterDate + ' - ' + fdicDates[index]
        );
        return [rowLabel + quarterAndFdicReleaseDates.join('     '), '', '', '', ''];
    }

    const getLastUpdateHeaderRow = (ratings: [string, InstitutionRating[]]) => {
        if (ratings && ratings[1]) {
            let lastUpdateHeaderRow = ratings[1].map(rating => dateFormatting(rating.effectiveDate))
            lastUpdateHeaderRow.unshift(t('listOfAccounts.ratings.csvLastUpdateHeader'));
            return lastUpdateHeaderRow;
        } else return [];
    }

    const getCsvRatingFileRows = (ratings: InstitutionRating[]) => {
        let csvRatingFileRows: string[][] = getInstructionRows(t('listOfAccounts.ratings.csvHeader'));
        const ratingsPerInstitution = Object.entries(groupBy(ratings, 'institutionName'));
        const quarterHeaderRow = getQuarterHeaderRow(ratingsPerInstitution[0]);
        csvRatingFileRows.push(quarterHeaderRow);
        const lastUpdateHeaderRow = getLastUpdateHeaderRow(ratingsPerInstitution[0]);
        csvRatingFileRows.push(lastUpdateHeaderRow);
        for (const [institutionName, ratingData] of ratingsPerInstitution) {
            csvRatingFileRows.push(getRatingRow(institutionName, ratingData));
        }
        csvRatingFileRows.push(blankRow());
        csvRatingFileRows.push(quarterEndingAndFdicReleaseRow(ratingsPerInstitution[0]))
        return csvRatingFileRows;
    }

    const formattedTableRows = sortedRows.map((row: AccountTableRow) => getDataPoints(row));

    return (
        <Stack
            spacing={2}
            sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                flexWrap: 'wrap'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                <TPOExportDropdown
                    sheetContent={getCsvRatingFileRows(props.ratings)}
                    sheetHeaders={getFirstInstructionRow(t('listOfAccounts.ratings.csvHeader'))}
                    fileName={'VeribancRatings'}
                    label={t('listOfAccounts.ratings.csvExport')}
                    width={'180px'}
                    dropDownTranslation={'62px'}
                    data-testid={VERIBANC_RATINGS_DROPDOWN_TESTID}
                />
                <Box sx={{width: '620px'}}/>
                <TPOExportDropdown
                    sheetContent={formattedTableRows}
                    sheetHeaders={convertToListOfLabel(accountTableHeaders)}
                    fileName={'AccountTable'}
                    label={t('listOfAccounts.exportAccountData')}
                    width={'180px'}
                    dropDownTranslation={'62px'}
                    data-testid={ACCOUNTS_PAGE_DROPDOWN_TESTID}
                />
            </Box>
            <TPOTable
                headers={accountTableHeaders}
                content={!showAll ? formattedTableRows.slice(0, 10) : formattedTableRows}
                sortable={{
                    order: order,
                    setOrder: setOrder,
                    orderBy: orderBy,
                    setOrderBy: setOrderBy
                }}
            />
            {!showAll && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <Button
                        variant="outlined"
                        onClick={() => setShowAll(true)}
                        sx={{
                            borderColor: 'primary.main',
                            color: 'primary.main',
                            backgroundColor: '#ffffff',
                            textTransform: 'none',
                            fontSize: '16px'
                        }}
                    >
                        Show all
                    </Button>
                </Box>
            )}
        </Stack>
    );
};

export default AccountTable;
