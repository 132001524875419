import i18n, { TOptionsBase } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    debug: true,
    react: { useSuspense: false },
    interpolation: {
      escapeValue: false
    },
    resources: {
      en: {
        translation: {
          analysesSummary: {
            title: 'cashVest Analyses',
            detail:
              'The cashVest analysis is a liquidity data tool to help you execute borrowing, investing, and cash management decisions with more confidence and greater ease.  Here you can find your current and prior cashVest analyses, including identification of data-supported opportunities for maximizing value and efficiencies.',
            selectTime: 'Select report period',
            statusUnpublished: 'Report status: unpublished',
            statusPublished: 'Report status: published on {{datePublished}}',
            downloadReport: 'Download Report',
            saveComments: 'Save Comments',
            publishReport: 'Publish Report',
            unpublishReport: 'Unpublish Report'
          },
          cashVestAnalyses: {
            title: 'cashVest Analyses',
            summary:
              'The cashVest analysis is a liquidity data tool to help you execute borrowing, investing, and cash management decisions with more confidence and greater ease.  Here you can find your current and prior cashVest analysis including identification of data-supported opportunities for maximizing value and efficiencies.',
            scoreCard: {
              tab: 'Scorecard',
              title: 'Scorecard',
              summary: 'Summary',
              driversOfYourScore: 'Drivers for Your Score',
              chartXLegend: 'Period ending',
              scoreWidgetTitle: '% of Funds Providing Value',
              liquidityWidgetTitle: 'Liquidity Proficiency',
              warnickWidgetTitle: 'Warnick Rate Indicator®',
              optimizationWidgetTitle: 'Cash Flow Optimization',
              investmentPolicyWidgetTitle: 'Investment Policy',
              impactText: {
                initial: 'Initial Report Score',
                unchanged: 'Unchanged from prior period',
                up: 'Up this period',
                down: 'Down this period'
              }
            },
            cashBoard: {
              tab: 'cashBoard',
              title: 'cashBoard ({{reportDate}})',
              summary:
                "The following data summarizes your organization's financial relationships and cash balances for the analyzed period.",
              annualBalanceChartToolTipTitle:
                'Current Status and Year-over-year Change Chart explained',
              annualBalanceChartToolTipContent:
                'This chart shows your total balances over the last 12 months for the selected group of accounts. It also compares the balances with the same period one and two years prior.',
              ttmBalanceTitle: '12 Month Average Balance ({{reportDate}})',
              averageBalanceTitle: 'Period Average Balance ({{reportDate}})'
            },
            liquidityProficiency: {
              tab: 'Long-term Liquidity',
              title: 'Long-term Liquidity',
              summary:
                "three+one's cashVest® Liquidity Analysis stress-tests your liquidity position, taking into account each transaction (every debit and credit from each account three+one had access to), to comprehensively assess its duration capability. The cashVest data identifies the potential to complement current investment strategies by utilizing the duration capability of existing cash balances to optimize opportunities for increased value.  Due to the cyclical nature of markets, various investments have strengths during different aspects of the market cycle.",
              selectAccountGroupLabel: 'Select account group',
              strategicLiquidityTitle: 'Strategic Liquidity',
              strategicLiquidityTotal: 'Total Strategic Liquidity',
              strategicLiquidityTableToolTipTitle: 'Strategic Liquidity Table',
              strategicLiquidityTableToolTipContent:
                'This table shows the results of the stress tests for the selected group of accounts. These results highlight specific balance amounts, broken down into various liquidity levels, which show how long and how much cash is available. This table also provides benchmarks that are intended to measure the minimum value that should be provided by those respective balances based on market rates at the time of the analysis.',
              workingCapitalChartToolTipTitle: 'Working capital explained',
              workingCapitalChartToolTipContent:
                'Working capital is used to meet daily liquidity needs. It can also provide additional value through offsetting banking fees and short-term investments. Working Capital is the difference between the average balance over the analyzed 12-month period and your total Strategic Liquidity.  For example, if your 12-month average balance was $11 million and your Strategic Liquidity totaled $10 million, the working capital would be $1 million.',
              investmentVsStrategicLiquidityLvlsTitle: 'Investments vs Strategic Liquidity Levels',
              investmentVsStrategicLiquidityLvlsChartToolTipTitle:
                'Investment vs Strategic Liquidity explained',
              investmentVsStrategicLiquidityLvlsChartToolTipContent:
                'The Investment vs. Strategic Liquidity chart breaks down your overall balances from the most recent 12-month period into three categories (Fixed Income, High-Yield Liquid, and Low/No-Yield Liquid).  With this chart, you can see if your investment levels are aligned with your data-supported Strategic Liquidity Levels.',
              investmentVsTotalBalancesChartToolTipTitle:
                'Strategic Liquidity vs Total Balances chart explained',
              investmentVsTotalBalancesChartToolTipContent:
                'The Strategic Liquidity vs Total Balances chart shows each of your data-supported Strategic Liquidity Levels as compared to your total balance over the past 12 months. The chart illustrates in graphical form what the Strategic Liquidity table above depicts.'
            },
            shortTermLiquidity: {
              tab: 'Short-term Liquidity',
              title: 'Short-term Liquidity',
              summary:
                'The following provides the data and tools to identify near-term cash needs and opportunities for short-term investments during high cash balance periods.',
              liquidityForecastTitle: 'three+one MC® Liquidity Forecast Model',
              liquidityForecastMessage: 'three+one MC® Liquidity Forecast Model',
              liquidityForecastSummaryP1:
                'To further support efforts to better invest short-term cash, three+one has spent several years developing its short-term forecast models. The three+one short-term forecast is a forward-looking forecast that provides strategic liquidity opportunity on all dollars above the cushion in the stress test results.',
              liquidityForecastSummaryP2:
                'The goal of this short-term forecast is to provide actionable data on how long monies are available and how much money according to the forecast results. These forecasts are a range that actual balances will fall within based on a high mathematical degree of confidence.',
              shortTermForecastTableHeader1: 'Week Ending',
              shortTermForecastTableHeader2: 'Total Forecast',
              shortTermForecastTableHeader3: 'Forecast Net of Strategic Liquidity',
              shortTermForecastTableHeader4: 'Low Forecast',
              shortTermForecastTableHeader5: 'Prior Year Actual',
              shortTermForecastChartToolTipTitle:
                'Short-term Cash Position Forecast chart explained',
              shortTermForecastChartToolTipContent:
                'This chart provides a forecast of balances over the next six months (green line) as compared with your actual cash balances for the same period of time last year (purple line), and your data-supported Strategic Liquidity (black line). The Low forecast (yellow line) provides a worst case scenario of what balances could be.',
              shortTermForecastTableToolTipTitle:
                'Short-term Cash Position Forecast table explained',
              shortTermForecastTableToolTipContent:
                'This table provides the Total Forecast balances forecasted for the next six months, the Forecast Net of your data-supported Strategic Liquidity, the worst-case scenario Low Forecast balances, and your Prior Year Actual balances. In each case, the values shown in the table are the minimum value for that calendar week.',
              monthlyNetChangeTitle: 'Monthly Net Change in Cash',
              monthlyNetChangeToolTipTitle: 'Monthly Net Change in Cash explained',
              monthlyNetChangeToolTipContent:
                'This table provides the sum of positive cash flows and sum of negative cash flows for each month and the net impact to monthly cash balances for the past 12 months.',
              varianceChartTitle: 'Daily, Weekly and Monthly Variance',
              varianceChartToolTipTitle: 'Daily, Weekly and Monthly Variance explained',
              varianceChartToolTipContent:
                'This chart provides the net change in balance for the selected time period. Daily variance shows the change in balance from one day to the next for the analyzed period. The weekly variance shows the net change from one week to the next, and the Monthly variance shows the net change from one month to the next. The detail of the monthly variance (sum of positive cash flows and sum of negative cash flows) can be seen in the Monthly Net Change in Cash table above.'
            },
            accountOptimization: {
              tab: 'Account Optimization',
              title: 'Account Optimization',
              summary:
                'The following analysis provides a detailed review of your financial relationships and account structure, identifying areas to improve efficiencies as well as reduce expenses.  An optimized account structure will simplify balance management and allow you to more easily maximize the value on all cash.',
              selectAccountGroupLabel: 'Select account group',
              bankByBankAnalysisTitle: 'Account Analysis',
              bankByBankAnalysisSubTitle: 'Bank By Bank Analysis',
              bankAccountAnalysisSubTitle: 'Account detail',
              bankByBankAnalysisHeading: 'cashVest Analysis',
              bankByBankAnalysisSummary:
                'This is a description. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur',
              bankByBankChartTitle: 'Balances by Bank',
              bankAccountChartTitle: 'Account detail',
              bankByBankChartToolTipTitle: 'Account Analysis Charts explained',
              bankByBankChartToolTipContent:
                'These stacked charts show the total average balance for the selected account group and/or financial institution.',
              bankByBankAnnualBalancesTitle: 'Annual Balances {{date}}',
              bankByBankPeriodBalancesTitle: 'Period Balances {{date}}',
              balancesFirstColumn: 'Ending Balance',
              balancesSecondColumn: 'Avg. Balance',
              balancesThirdColumn: 'Min Balance',
              balancesFourthColumn: 'Interest Rate',
              balancesFifthColumn: 'Interest Sum'
            },
            treasuryServices: {
              tab: 'Treasury Services',
              title: 'Treasury Services',
              summary:
                'The following provides a detailed analysis of your monthly Treasury Analysis statements for the period.  These are the billing statements for services provided by the bank.  They provide line item detail for each service provided as well as the assessed value for your balances on deposit. The three+one Analysis includes a standardized categorization of fees and earnings to enable comparisons across different financial institutions and identifies opportunities for maximizing value, increasing service efficiency and reducing costs.',
              selectAccountGroupLabel: 'Select Treasury Analysis Group',
              BalanceSummaryTabTitle: 'Balance Summary',
              ECRTabTitle: 'Earnings Credit Rate (ECR)',
              TotalFeesAndCreditsTabTitle: 'Total Fees and Credits',
              LineItemFeesTabTitle: 'Line Item Fees',
              balanceSummaryToolTipContent:
                'This table compares the balance available to offset fees during the analyzed period and the balance that was needed to offset fees, based on the average ECR during the analyzed period.  An excess balance indicates the average balance was more than sufficient to cover fees while a deficit balance indicates that balances weren’t sufficient to offset fees.',
              balanceNeededToOffsetFeesToolTipContent:
                'Balance needed to offset fees is calculated based on the average monthly line item fees during the analyzed period and the average ECR for the period.',
              balanceAvailableToOffsetFeesToolTipContent:
                'Balance available to offset fees is the ledger balance net of any balances that are ineligible for earnings credit. Typically it is the average ledger balance minus items such as float, reserve requirement, and/or balances earning interest.',
              FDICToolTipContent:
                'Each bank has a different name for this fee. It is generally a passthrough fee for FDIC insurance and broad bank overhead. Beyond FDIC insurance on up to two accounts totaling $500,000, this fee does not provide value.',
              ECRToolTipContent:
                'This table provides a high-level overview of the impact of FDIC fees on earnings credit.  Earnings credit is directly reduced by any balance based fees. For example, if a reported ECR is 0.75%, but the bank charges a 0.10% balance based fee, then the true value of the ECR is 0.65%. When an FDIC fee is assessed, more balances are required to offset all fees',
              TotalFeesAndCreditsToolTipContent:
                'This table provides a high-level overview of the total line item fees incurred during the analyzed period as compared with earnings credit that offset these fees.  Depending on your settlement period, hard fees may be debited monthly, quarterly, semi-annually, or annually.  Excess credit indicates balances exceed those needed to offset fees while deficit balances may result in hard charges during the settlement period.',
              LineItemFeesTableToolTipContent:
                'The Line Item Fees table provides the categories of fees, as defined by three+one, incurred during the period as well as the volume and average unit price per transaction.  three+one standardizes fee categories to enable benchmarking against other financial providers.',
              LineItemFeesChartToolTipContent:
                'The Distribution of Fees chart provides an overview of the total fees by category, as defined by three+one, incurred during the period.  It also provides each category’s respective percentage of the total fees.  three+one standardizes fee categories to enable benchmarking against other financial providers.',
              ReportingAndMaintenanceToolTipContent:
                'Reporting and maintenance fees are overhead fees not related to a single specific service. They include things like online reporting access, fraud protection, paper documents, etc.',
              BalanceRelatedFeesToolTipContent:
                'Balance related fees include FDIC charges, interest expense, and negative balance charges.  It should be noted that FDIC charges, which are not related to any additional service provided, complicate the calculation of balances required to offset fees as these fees are directly impacted by the level of balances on deposit.',
              LineItemFeesChartTitle: 'Distribution of fees'
            }
          },
          common: {
            cashVestAnalysisHeading: 'cashVest Analysis',
            selectAccountGroupLabel: 'Select account group',
            explainChart: 'Explain this chart',
            explainTable: 'Explain this table',
            selectView: 'Select View',
            selectVariance: 'Select Variance',
            applyFilters: 'Apply Filters',
            clearFilters: 'Clear Filters',
            exportTable: 'Export',
            explainBankRating: 'Explain VERIBANC Rating'
          },
          error: {
            invalidEmail: 'Invalid email address',
            invalidPassword: 'Invalid password',
            invalidPasswordSetup:
              'Invalid password. Make sure your password fits all the requirements.',
            notAuthorized: 'Invalid credentials',
            invalidPhoneNumber: 'Invalid phone number',
            invalidOtp: 'Invalid code',
            notAuthorizedOtp: 'Code mismatch',
            failedPhoneNumber: 'Failed to intiate MFA. Please check your number and try again.'
          },
          investments: {
            title: 'List of Investments',
            chartToolTipTitle: 'Investment maturities explained',
            chartToolTipContent:
              'This chart provides a timeline of your investments and their respective maturities over the next 12 months.',
            newInvestmentButton: 'New investment',
            timelineChartTitle: 'Investment Maturities (displayed by calendar week)',
            addInvestment: {
              cancelTPODialog: {
                title: 'Discard changes?',
                content:
                  "Are you sure you want to close? All pending data you've entered will be lost.",
                goBackButton: 'Go Back',
                okayButton: 'OK'
              },
              deleteTPODialog: {
                title: 'Delete investment?',
                content: 'Are you sure you want to delete this investment?',
                goBackButton: 'Cancel',
                okayButton: 'Delete'
              },
              discardChangesEditPageTPODialog: {
                title: 'Discard changes?',
                content: 'Are you sure? All changes will be lost.',
                goBackButton: 'Go Back',
                okayButton: 'OK'
              },
              warningTPODialog: {
                title: 'Is this correct?',
                content: 'Interest rate is higher than expected',
                goBackButton: 'Go Back',
                okayButton: 'Proceed, this is correct'
              },
              navigation: {
                newTab: 'New',
                addedTab: 'Added',
                title: 'New investment'
              },
              newPage: {
                newAccountWarning:
                  'For new investment accounts and relationships, please contact your Relationship Specialist to set up an account in order to add an investment.',
                warning: 'To edit a submitted investment, please contact your RM ',
                rmEmail: 'support@threeplusone.us',
                addInvestmentButton: 'Add another investment',
                reviewButton: 'Review for submission',
                cancelButton: 'Cancel'
              },
              addedPage: {
                emptyAddedListMessage: 'No investments added yet',
                addAnotherInvestmentButton: 'Add another investment',
                submitAllButton: 'Submit all',
                submitAllSuccessfulTPODialog: {
                  title: 'Successfully submitted',
                  content:
                    'Submitted changes will be available momentarily. Refresh your screen to see the updated investments.',
                  okayButton: 'OK'
                },
                submitAllUnsuccessfulTPODialog: {
                  title: 'Failed to submit',
                  content: 'Something went wrong, please contact your RM for assistance.',
                  goBackButton: 'Go Back'
                }
              },
              editPage: {
                saveButton: 'Save',
                backButton: 'Back',
                deleteButton: 'Delete investment'
              },
              investmentForm: {
                account: 'Account',
                investmentType: 'Investment Type',
                purchaseAmount: 'Purchase Amount',
                interestRate: 'Interest Rate',
                faceValue: 'Face Value',
                purchaseDate: 'Purchase Date',
                maturityDate: 'Maturity Date',
                callDate: 'Call Date',
                couponRate: 'Coupon Rate',
                investmentId: 'Investment ID',
                fund: 'Fund',
                optional: 'Optional',
                purchaseDateTooltip:
                  'For US Treasuries the purchase date is the Settlement date on your confirmation.',
                callDateTooltip:
                  "A call date is entered for callable bonds that allow the issuer to redeem them before their scheduled maturity. Not all bonds have call dates, so this field may not be required. The presence of a call provision varies based on the investment's terms and conditions. If you have further questions, please consult your broker-dealer or investment advisor.",
                couponRateTooltip:
                  'A coupon rate is the fixed annual interest rate paid by certain fixed-income investments, such as Treasury Notes & Bills, municipal bonds, government bonds, or corporate bonds. It represents the periodic interest payment made to investors, typically paid semi-annually or annually. Not all fixed-income investments have coupon rates. If you have further questions, please consult your broker-dealer or investment advisor.',
                faceValueTooltip:
                  'The value that will be received at the time of maturity. For CDs and similar investments that are not purchased at a premium or a discount, this value will automatically populate with the purchase amount.',
                accountTooltip:
                  'The Account number is typically the abbreviated name of the bank followed by the abbreviated investment type. For instance JPMorgan Treasury Bills would be xJPMCTB. Please contact your relationship specialist for assistance if the desired account does not appear in the drop down list.',
                interestRateTooltip:
                  'The interest rate will automatically populate for certain investment types such as Treasury Bills, once all required elements - purchase amount, face value, purchase date and maturity date - have been populated.'
              }
            },
            filterByDate: 'Filter by maturity date'
          },
          accountDetailsPage: {
            accountsListTab: 'List of accounts',
            investmentsTab: 'Investments'
          },
          listOfAccounts: {
            chartTitle: 'Financial Institution Balances',
            chartDefaultFiltersLabel: 'All banks',
            accountDetailsTitle: 'Account details',
            dataUpdatedThrough: 'Data updated through {{date}}',
            accountDetailsSummary:
              'The following provides a summary of all of your accounts and investments tracked by three+one.  If there are any additions or changes you’d like incorporated into these lists, please let us know by contacting your relationship specialist.',
            balanceAsOfDate: 'Total balance as of {{balanceAsOfDate}}',
            maturingInvestments:
              'Investments maturing within 45 days totaling ${{maturingInvestments}}',
            interestRateToolTipTitle: 'Interest rate',
            interestRateToolTipContent:
              'The interest rate shown here is the annualized interest rate calculated from the actual interest received during the month and the monthly average balance.',
            monthlyRateToolTipTitle: 'Monthly interest',
            monthlyRateToolTipContent:
              'For all accounts, the monthly interest shows the actual interest received during the most recent month for which data is available.',
            chartToolTipTitle: 'Balances by Institution explained',
            chartHeader: 'Daily balances by institution',
            chartToolTipContent:
              'This chart provides your total balances, including investments, by financial institution over the past 12 months. Filters can be applied separately and show the data specific to the balances and investments by selected institutions and product types.',
            accountTableAccountHeader: 'Account',
            accountTableFinancialInsititutionHeader: 'Financial institution',
            accountTableTypeHeader: 'Type',
            accountTableBalanceHeader: 'Balance as of',
            accountTableLastBalanceHeader: 'Last balance',
            accountTableMonthlyAverageHeader: 'Monthly average',
            accountTableMonthlyInterestHeader: 'Monthly interest',
            accountTableInterestRateHeader: 'Interest rate',
            accountTableAccountStatus: 'Account Status',
            exportAccountData: 'Export Account Data',
            legendExplanationTitle: 'Legend explanation',
            ratings : {
              legendExplanation: 'Where applicable, each bank\'s VERIBANC rating is included in the parentheses following the bank name in the legend above. ' +
                'VERIBANC ratings updated as of {{effectiveDate}} for the quarter ending {{quarterEnding}}. See more information on VERIBANC\'s rating system and historical data ' +
                'by clicking "? Explain this VERIBANC Rating" or "Download VERIBANC Ratings."',
              bankRatingToolTipTitle: 'Explain VERIBANC Rating',
              bankRatingToolTipContent: 'How does VERIBANC rate banks and credit unions?\n' +
                '\n' +
                'VERIBANC uses a system of color (Green, Yellow, Red) and star ratings to evaluate financial health based on key metrics like capitalization, asset quality, and earnings. ' +
                'The color rating is based on current standings while the star rating is forward looking based on potential risk of future failure.\n' +
                '\n' +
                'Green (G): Strong financial health and stability.\n' +
                '\n' +
                'Yellow (Y): Some concerns; monitoring is recommended.\n' +
                '\n' +
                'Red (R): Potential financial risk; further action may be necessary.\n' +
                '\n' +
                'Three Stars (***) (Highest): Indicates excellent financial health. The institution demonstrates strong capitalization, superior asset quality, and robust earnings. It is highly stable and poses minimal risk.\n' +
                '\n' +
                'Two Stars (**) (Good): Reflects solid financial health. The institution is well-capitalized and maintains good asset quality, although there may be areas for improvement.\n' +
                '\n' +
                'One Star (*) (Weak): Suggests financial weaknesses. The institution may be undercapitalized, have poor asset quality, or demonstrate signs of financial stress. Caution and further review are advised.\n' +
                '\n' +
                'No Stars (None) (Lowest): The institution does not meet the criteria above.\n' +
                '\n' +
                'All bank ratings are independently provided by our partner, VERIBANC which is the leading provider of bank safety ratings and research. Historical VERIBANC ratings can be downloaded ' +
                'from the “Download VERIBANC Ratings.” The source of rating data is www.veribanc.com. Please see their website for more information. VERIBANC ratings may not be redistributed or otherwise made known without VERIBANC’s expressed written permission.',
              bankRatingLink: 'https://veribanc.com/',
              bankRatingLinkText: 'VERIBANC',
              csvExport: 'Download VERIBANC Ratings',
              csvHeader: 'Instructions:\n' +
                'How does VERIBANC rate banks and credit unions?\n' +
                'VERIBANC uses a color-coded system (Green, Yellow, Red) and Star Ratings to evaluate financial health based on key metrics like capitalization, asset quality, and earnings.\n' +
                'Green: Strong financial health and stability.\n' +
                'Yellow: Some concerns; monitoring is recommended.\n' +
                'Red: Potential financial risk; further action may be necessary.\n' +
                'VERIBANC Star Ratings Explained\n' +
                'Three Stars (***) (Highest): Indicates excellent financial health. The institution demonstrates strong capitalization, superior asset quality, and robust earnings. It is highly stable and poses minimal risk.\n' +
                'Two Stars (**) (Good): Reflects solid financial health. The institution is well-capitalized and maintains good asset quality, although there may be areas for improvement.\n' +
                'One Star (*) (Weak): Suggests financial weaknesses. The institution may be undercapitalized, have poor asset quality, or demonstrate signs of financial stress. Caution and further review are advised.\n' +
                'No Stars (None) (Lowest): The institution does not meet the criteria above.\n' +
                'Blue Ribbon Rating: A "B" or double "BB" represents a Blue Ribbon bank rating. A single "B" immediately following our rating indicates that this institution ' +
                'has been a Blue Ribbon bank from 1 to 7 consecutive quarters. A double "BB" immediately following our rating indicates that this institution has been a Blue Ribbon bank for 8 or more consecutive quarters.\n' +
                '\n' +
                'The source of rating data is www.veribanc.com. Please see their website for more information.\n' +
                'VERIBANC ratings may not be redistributed or otherwise made known without VERIBANC\'s expressed written permission.\n',
              csvLastUpdateHeader: 'Veribanc Rating Last Updated',
              csvFdicText: 'Financial Quarter Ending - Data Release Dates: '
            }
          },
          listOfInvestments: {},
          login: {
            forgottenPasswordTitle: 'Forgotten password',
            forgottenPasswordMessage:
              'To reset your password, please provide your Email address on record. You will be sent an email with steps to help you create a new password.',
            forgottenPasswordLink: 'Forgotten password',
            passwordLink: 'Send forgotten password link',
            checkYourEmailTitle: 'Check your email',
            checkYourEmailSubitle:
              'If we recognise the email you provided, you will receive a password reset link.',
            checkYourEmailMessage:
              "Please check your email inbox for a link to reset your password. If you don't receive an email check you spam folder or email {{emailAddress}}",
            loginTitle: 'Login',
            loginButton: 'Login',
            username: 'Username',
            email: 'Email',
            password: 'Password',
            hidePassword: 'Hide',
            showPassword: 'Show',
            usernameToolTip:
              'Your username is the email address used to create your cashVest account.',
            passwordToolTip: 'Your password is used to securely access your cashVest account.',
            setupPasswordTitle: 'Setup your password',
            setupPasswordMessage:
              "Welcome to three+one. Enter your new password and you'll be able to log into your cashVest account.",
            setupPasswordConstraints:
              'Your password must be at least 12 characters long and it should contain at least one of each of the following: a lower case letter, an upper case letter, a number and a symbol.',
            setupPasswordLink: 'Submit',
            setupPasswordCreate: 'Create password',
            setupPasswordConfirm: 'Confirm new password',
            matchError: 'Passwords must match',
            passwordSetupSuccessTitle: 'Password successfully set',
            passwordSetupSuccessMessage:
              'Thank you for setting your password. You can now login using your email and the new password.',
            mfaSetupSuccessTitle: 'MFA successfully set',
            mfaSetupSuccessMessage:
              'Thank you for setting your second factor of authentication. You can now login using your email and your password, and when asked for your one time code.',
            welcomeMessage:
              'At three+one, we aim to provide clients with data and tools to ensure monies on deposit are providing maximum value; to look for opportunities to enhance treasury efficiencies; and to utilize cash to provide additional income.'
          },
          mfa: {
            title: 'Please, set up your second factor of authentication',
            message:
              'For increased security, please set up your multi-factor authentication (MFA) to help protect your cashVest account and your data.  Please enter your phone number, including + country code and area code (e.g. +1585551234) and we will send you a one time use code to validate your MFA.',
            submit: 'Submit',
            phoneNumberLabel: 'Phone number',
            phoneNumberToolTip:
              'Your phone number is the number used to send your one time code. Use the country (including the +) followed by your local area code and phone number. For example, +15855551234',
            oneTimeCodeLabel: 'One time code',
            oneTimeCodeToolTip:
              "Your one time code is the code used to securely protect your account with a second factor of authentication. The cashVest system will send you an SMS with a temporary code. You'll always need to enter a new one time use code every time you access cashVest."
          },
          navigation: {
            account: 'Account',
            accountList: 'Account Details',
            analyses: 'cashVest Analyses',
            back: 'Back',
            backToLogin: 'Back to login',
            dashboard: 'Live cashBoard',
            logout: 'Log out',
            profile: 'Your Profile',
            rates: 'Explore rates'
          },
          formValidationError: {
            account: 'Please select a valid account',
            investmentTypeId: 'Please select an investment type',
            amount: 'Please enter a valid amount',
            rate: 'Please enter a valid rate',
            date: 'Please select a valid date',
            numberTypeError: 'Please enter a valid number',
            dateTypeError: 'Please enter a valid date',
            maturityDateError: 'Check entered dates. Maturity date must be after purchase date.'
          },
          relationshipManager: {
            title: 'Welcome {{username}}',
            message:
              "This is your three+one Account, from here you have access to view your client's account details and their cashVest analyses reports. You can also choose to view all three+one clients account details and cashVest analyses.",
            viewClientsHeader: 'View clients',
            analysis: 'This is where an analysis done by an RM would be displayed.'
          },
          tpoBrand: {
            footer: '© Three+One Company, Inc.',
            disclaimerBold:
              'Your valued resource for financial analysis and insight under our trusted brands: cashVest®, rfpPrep®, higherEd Trust®, muniTrust®, and molitrust®.',
            disclaimer:
              'All the content on cashVest.us is informational in nature and aimed at simplifying cash management decisions. The data should not be considered investment, financial, or other advice. Nothing on cashVest.us constitutes a solicitation, recommendation, endorsement, or offer by three+one to buy or sell any financial instruments/securities. The data may not be a comprehensive picture of your liquidity, and the information, reports, and related materials are only based on data provided by you to three+one. You or your advisors are ultimately responsible for any investment decisions. All information, reports, and related materials are the property of three+one and are protected by applicable copyright or other laws and may not be republished or distributed without the express written consent of three+one.'
          },
          yourProfile: {
            personalDetails: {
              title: 'Your Personal Details',
              firstName: 'First Name',
              lastName: 'Last Name',
              phoneNumber: 'Phone Number',
              noPhoneNumberStored: 'No phone number stored',
              updateSucess: 'Successfully updated!',
              updateError: 'There was an error updating your details!',
              errorFetchingData: 'Sorry, there was an error fetching your user details',
              editName: {
                updateName: 'Update Name',
                newFirstName: 'Enter new first name',
                newLastName: 'Enter new last name',
                save: 'Save',
                successSnackbar: 'Name sucessfully updated',
                errorSnackbar: 'Sorry, there has been an error when updating your name'
              },
              editPhoneNumber: {
                updateNumber: 'Update Number',
                updatePhoneNumber: 'Update phone number',
                newPhoneNumber: 'Enter new number',
                confirmNewPhoneNumber: 'Confirm new number',
                save: 'Save',
                selectCountryCode: 'Country code',
                errors: {
                  invalidNumber: 'Please enter numbers only',
                  required: 'Input required here',
                  phoneNumbersMustMatch: 'Phone numbers must match',
                  minDigits: 'Phone number should be at least 7 digits long.',
                  maxDigits: 'Phone number should be no more than 15 digits long.'
                }
              }
            },
            emailPreferences: {
              title: 'Your Email Subscriptions',
              emailsHeader: 'Emails',
              errorFetchingData: 'Sorry, there was an error fetching your email preferences',
              fields: {
                oneDayInvestmentMaturitySubscription: '1 day maturity notifications',
                sevenDayInvestmentMaturitySubscription: '7 day maturity notifications'
              },
              edit: {
                actionButton: 'Edit email preferences',
                title: 'Edit email preferences',
                saveButton: 'Save',
                successSnackbar: 'Email preferences were sucessfully updated',
                errorSnackbar: 'Sorry, there has been an error when updating your email preferences'
              }
            },
            loadingMessage: 'Loading...',
            errorFetchingData: 'Sorry, there was an error fetching your data'
          }
        }
      }
    }
  });

export default i18n;
export const t = (key: string, options?: TOptionsBase): string => i18n.t(key, options) as string;
